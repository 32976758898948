import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import usersReducer from './user';
import errorsReducer from './error';
import offersReducer from './offers';
import successReducer from './success';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  usersReducer: usersReducer,
  errorsReducer: errorsReducer,
  offersReducer: offersReducer,
  successReducer: successReducer,
});

export default reducer;
