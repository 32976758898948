import { GET_USERS, GET_AUTH_USER } from '../types';

const initialState = {
    users: [],
    authUser: {}
};
export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            };
        case GET_AUTH_USER:
            return {
                ...state,
                authUser: action.payload
            };
        default:
            return state;
    }
}
