// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// action - users reducer
export const GET_USERS = 'GET_USERS';
export const GET_AUTH_USER = 'GET_AUTH_USER';

// action - offers reducer
export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFER = 'GET_OFFER';

// action - error reducer
export const GET_ERRORS = 'GET_ERRORS';
export const GET_ERRORS_PASSWORD = 'GET_ERRORS_PASSWORD';
// action - seccess reducer
export const GET_SUCCESS = 'GET_SUCCESS';
export const DELETE_SECCESS = 'DELETE_SECCESS';
export const GET_SUCCESS_PASSWORD = 'GET_SUCCESS_PASSWORD';
