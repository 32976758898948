// assets
import { IconAlbum, IconUser, IconUsers } from '@tabler/icons';

// constant
// const icons = { IconBrandChrome, IconUser, IconUsers };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'profile',
            title: 'Profil',
            type: 'item',
            url: '/profile',
            icon: IconUser,
            breadcrumbs: false
        },
        {
            id: 'employees',
            title: 'Employés',
            type: 'item',
            url: '/employees',
            icon: IconUsers,
            breadcrumbs: false
        }
        ,
        {
            id: 'offers',
            title: 'Offres',
            type: 'item',
            url: '/offers',
            icon: IconAlbum,
            breadcrumbs: false
        }
    ]
};

export default pages;
