import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

import { logout } from './store/action/user';

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const customization = useSelector((state) => state.customization);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    const token = localStorage.token;
    if (token) {
      const decodedJwt = parseJwt(token);
      const expiryDate = new Date(decodedJwt.exp * 1000);
      if (expiryDate < Date.now()) {
        dispatch(logout(navigate));
      }
    }
    // else{
    //     dispatch(logout(navigate));
    // }
  });

  useEffect(() => {
    const token = localStorage.token;
    if (!token) {
      dispatch(logout(navigate));
    }
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
