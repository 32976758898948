import { GET_OFFERS, GET_OFFER } from '../types';

const initialState = {
    offers: [],
    offer: {}
};
export default function offersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_OFFERS:
            return {
                ...state,
                offers: action.payload
            };
        case GET_OFFER:
            return {
                ...state,
                offer: action.payload
            };
        default:
            return state;
    }
}
