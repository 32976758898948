import axios from 'axios';
import {
  GET_ERRORS,
  GET_USERS,
  GET_AUTH_USER,
  GET_ERRORS_PASSWORD,
  GET_SUCCESS_PASSWORD,
} from '../types';
import setAuthToken from '../../token/authtoken';
import { GET_SUCCESS } from './../types';

export const login = (userData, navigate) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .post(`${process.env.REACT_APP_API_URL}/auth`, userData)
    .then((res) => {
      dispatch({
        type: GET_AUTH_USER,
        payload: res.data,
      });
      const { accessToken } = res.data;
      localStorage.setItem('token', accessToken); //save token in the local storage
      navigate('/profile');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUsers = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/users`)
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // console.log(err);
    });
};

export const getAuthUser = () => (dispatch) => {
  setAuthToken();
  axios
    .get(`${process.env.REACT_APP_API_URL}/authuser`)
    .then((res) => {
      dispatch({
        type: GET_AUTH_USER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createUser = (userData) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .post(`${process.env.REACT_APP_API_URL}/users/create`, userData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { user: 'User Created succesfully' },
      });
      dispatch(getUsersList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    );
};

export const editUser = (userData, id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .put(`${process.env.REACT_APP_API_URL}/updateUser/${id}`, userData)
    .then((res) => {
      console.log('res', res);
      dispatch({
        type: GET_AUTH_USER,
        payload: res.data.result,
      });
      dispatch({
        type: GET_SUCCESS,
        payload: res.data.message,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    );
};

export const passwordReset = (passwordData) => (dispatch) => {
  setAuthToken();
  axios
    .put(`${process.env.REACT_APP_API_URL}/resetPassword`, passwordData)
    .then((res) => {
      console.log('res', res);
      dispatch({
        type: GET_SUCCESS_PASSWORD,
        payload: res.data.message,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS_PASSWORD,
        payload: err.response.data,
      }),
    );
};

export const forgetPassword = (data) => (dispatch) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/forgetPassword`, data)
    .then((res) => {
      dispatch({
        type: GET_SUCCESS_PASSWORD,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS_PASSWORD,
        payload: err.response.data,
      }),
    );
};

export const logout = (navigate) => () => {
  // Remove token from local storage
  localStorage.removeItem('token');
  navigate('/pages/login');
};
