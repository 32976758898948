import { GET_ERRORS, GET_ERRORS_PASSWORD } from '../types';

const initialState = {
  errorMsge: null,
  errorNewPsw: null
};
export default function err(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errorMsge: action.payload,
      };
      case GET_ERRORS_PASSWORD:
      return {
        ...state,
        errorNewPsw: action.payload,
      };
    default:
      return state;
  }
}
