import { GET_SUCCESS, DELETE_SECCESS, GET_SUCCESS_PASSWORD } from '../types';

const initialState = {
  successMsge: null,
  successDelete: null,
  successNewPsw: null
};
export default function success(state = initialState, action) {
  switch (action.type) {
    case GET_SUCCESS:
      return {
        ...state,
        successMsge: action.payload,
      };
      case DELETE_SECCESS:
      return {
        ...state,
        successDelete: action.payload,
      };
      case GET_SUCCESS_PASSWORD:
      return {
        ...state,
        successNewPsw: action.payload,
      };
    default:
      return state;
  }
}
