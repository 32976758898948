import { lazy } from 'react';
// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('../views/dashboard/Default')),
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import('../views/utilities/Typography')),
);
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(
  lazy(() => import('../views/utilities/MaterialIcons')),
);
const UtilsTablerIcons = Loadable(
  lazy(() => import('../views/utilities/TablerIcons')),
);

// sample page routing
const EmployeesPage = Loadable(lazy(() => import('../views/employees-page')));
const ProfilePage = Loadable(lazy(() => import('../views/profile')));
const OffersPage = Loadable(lazy(() => import('../views/offers')));
const OfferPage = Loadable(lazy(() => import('../views/offers/Offer')));
const UpdateOfferPage = Loadable(
  lazy(() => import('../views/offers/UpdateOffer')),
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    // {
    //     path: '/token/:token',
    //     element: <DashboardDefault />
    // },
    {
        path: '/',
        element: <ProfilePage />
    },
    // {
    //     path: '/dashboard/default',
    //     element: <DashboardDefault />
    // },
    // {
    //     path: '/utils/util-typography',
    //     element: <UtilsTypography />
    // },
    // {
    //     path: '/utils/util-color',
    //     element: <UtilsColor />
    // },
    // {
    //     path: '/utils/util-shadow',
    //     element: <UtilsShadow />
    // },
    // {
    //     path: '/icons/tabler-icons',
    //     element: <UtilsTablerIcons />
    // },
    // {
    //     path: '/icons/material-icons',
    //     element: <UtilsMaterialIcons />
    // },
    {
      path: '/employees',
      element: <EmployeesPage />,
    },
    {
      path: '/profile',
      element: <ProfilePage />,
    },
    {
      path: '/offers',
      element: <OffersPage />,
    },
    {
      path: '/offer',
      element: <OfferPage />,
    },
    {
      path: '/offer/:idOffer',
      element: <UpdateOfferPage />,
    },
  ],
};

export default MainRoutes;
