import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SnackbarContent,
  Snackbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AnimateButton from './extended/AnimateButton';
import { passwordReset } from '../store/action/user';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SUCCESS_PASSWORD, GET_ERRORS_PASSWORD } from '../store/types';
import CloseIcon from '@mui/icons-material/Close';

export default function DraggableDialog(props) {
  const { title, open } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const success = useSelector((state) => state.successReducer.successNewPsw);
  const errorr = useSelector((state) => state.errorsReducer.errorNewPsw);
  const [submit, setSubmit] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const initAlert = {
    message: '',
    backgroundColor: '',
    open: false,
  };
  const [alert, setAlert] = useState(initAlert);


  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    props.handleClickClose();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(initAlert);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  useEffect(() => {
    let newAlt = {};
    if (success) {
      newAlt = {
        message: success,
        backgroundColor: '#2e7d32',
        open: true,
      };
      setTimeout(() => {
        handleClose()
        
      }, 2000);

    } else if (errorr) {
      newAlt = {
        message: errorr,
        backgroundColor: '#d32f2f',
        open: true,
      };
    }
    setAlert(newAlt);
  }, [success, errorr]);
  useEffect(() => {
    dispatch({
      type: GET_SUCCESS_PASSWORD,
      payload: null,
    });
    dispatch({
      type: GET_ERRORS_PASSWORD,
      payload: null,
    });
    setAlert(initAlert);
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {}, [props]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
        {alert && alert.open ? (
        <Snackbar
          style={{ top: 120 }}
          open={alert.open}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          message="Note archived"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <SnackbarContent
            style={{
              backgroundColor: alert.backgroundColor,
            }}
            action={action}
            message={<span id="client-snackbar"> {alert.message} </span>}
          />
        </Snackbar>
      ) : null}
          <DialogContentText>
            <Formik
              initialValues={{
                currentPassword: '',
                password: '',
                password2: '',
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                currentPassword: Yup.string().max(255).required('Champ requis'),
                password: Yup.string().max(255).required('Champ requis'),
                password2: Yup.string()
                  .max(255)
                  .required('Champ requis')
                  .oneOf(
                    [Yup.ref('password'), null],
                    'Les mots de passe doivent correspondre',
                  ),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting },
              ) => {
                try {
                  // if (scriptedRef.current) {
                  //   setStatus({ success: true });
                  //   setSubmitting(false);
                  // }
                  // if (Boolean(isSubmitting)){
                  setAlert(initAlert);
                  dispatch({
                    type: GET_SUCCESS_PASSWORD,
                    payload: null,
                  });
                  dispatch({
                    type: GET_ERRORS_PASSWORD,
                    payload: null,
                  });
                  dispatch(passwordReset(values));
                  // }
                } catch (err) {
                  console.error(err);
                  // if (scriptedRef.current) {
                  //   setStatus({ success: false });
                  //   setErrors({ submit: err.message });
                  //   setSubmitting(false);
                  // }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                // isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit} {...props.others}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.currentPassword && errors.currentPassword,
                    )}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password-login">
                      Mot de passe actuel
                    </InputLabel>
                    <OutlinedInput
                      id="currentPassword"
                      type={showCurrentPassword ? 'text' : 'password'}
                      value={values.currentPassword}
                      name="currentPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showCurrentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Mot de passe actuel"
                      inputProps={{}}
                    />
                    {touched.currentPassword && errors.currentPassword && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-currentPassword-login"
                      >
                        {errors.currentPassword}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password-login">
                      Nouveau mot de passe
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Nouveau mot de passe"
                      inputProps={{}}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    error={Boolean(touched.password2 && errors.password2)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password2-login">
                      Confirmer le nouveau mot de passe
                    </InputLabel>
                    <OutlinedInput
                      id="password2"
                      type={showPassword2 ? 'text' : 'password'}
                      value={values.password2}
                      name="password2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirmer le nouveau mot de passe"
                      inputProps={{}}
                    />
                    {touched.password2 && errors.password2 && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password2-login"
                      >
                        {errors.password2}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                      <Button
                        disableElevation
                        // disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Enregistrer
                      </Button>
                    </AnimateButton>
                  </Box>
                </form>
              )}
            </Formik>
            {/* <FormPasswordReset
              handleSave={handleSave}
              submit={submit}
              handleClose={handleClose}
            /> */}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSave}>Enregistrer</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
